import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'

const test = true

const mainAxiosConfig = {
    baseURL: test ? 'http://127.0.0.1:8000/api/v1' : 'https://erpcore.api.wholesale.flip-the-switch.tech/api/v1'
}

const mainAxios = axios.create(mainAxiosConfig)

export { mainAxios }

// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'

import orders from '@src/views/apps/orders/store'

import articleGroups from '@src/views/apps/articleGroups/store'

const rootReducer = { navbar, layout, auth, orders, articleGroups }

export default rootReducer

// ** Reduc Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { mainAxios } from '@src/utility/Axios'

export const getArticleGroups = createAsyncThunk('appArticleGroups', async () => {
    const res = await mainAxios.get('/articleGroups')
    return res.data.data
})

export const fetchArticleGroups = createAsyncThunk('appArticleGroups', async (articleGroups) => {
    const res = await mainAxios.get('/articleGroups', { articleGroups })
    return res.data.data
})

export const addArticleGroup = createAsyncThunk('appArticleGroups', async (event, { dispatch, getState }) => {
    await mainAxios.post('/articleGroups', { name: event.name, description: event.description })
    await dispatch(fetchArticleGroups(getState().articleGroups))
    return event
})

export const updateArticleGroup = createAsyncThunk('appArticleGroups', async (event, { dispatch, getState }) => {
    await mainAxios.put(`/articleGroups/${event.id}`, { name: event.name, description: event.description })
    await dispatch(fetchArticleGroups(getState().articleGroups))
    return event
})

export const removeArticleGroup = createAsyncThunk('appArticleGroups', async (id) => {
    await mainAxios.delete(`/articleGroups/${id}`)
    return id
})

export const appArticleGroupsSlice = createSlice({
    name: 'appArticleGroups',
    initialState: {
        articleGroups: [],
        selectedArticleGroup: {}
    },
    reducers: {
        selectArticleGroup: (state, action) => {
            state.selectedArticleGroup = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getArticleGroups.fulfilled, (state, action) => {
            state.articleGroups = action.payload
        })
    }
})

export const { selectArticleGroup } = appArticleGroupsSlice.actions

export default appArticleGroupsSlice.reducer
